body{
    padding: 0 !important;
}
.nav-user{
    width: 100%;
    background-color: rgba(45, 83, 87, 0.5);
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    padding: 0 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #b0ef966b;
    border-radius: 10px;
    z-index: 5;
}
.nav-user .logo-sidebar{
    width: 150px;
    height: 40px;
    object-fit: contain;
}

.sidebar .logo-sidebar{
    width: 134px;
    height: 45px;
    object-fit: contain;
}
.sidebar .line-separator{
    border-color: white;
    border-top: 2px solid white;
    opacity: 1;
    margin: 0;
    margin-bottom: 20px;
}

.sidebar .line-separator-2{
    margin-bottom: 5px !important;
}

.sidebar{
    position: fixed;
    top: 0;
    left: -100%;
    right: 0;
    bottom: 0;
    width: 75%;
    height: 100%;
    background: #356a6fd1;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    border-right: 2px solid #b0ef966b;
    transition: left 0.3s ease;
    z-index: 100;
    max-width: 400px;
    padding: 0;
    overflow: auto;
}

.icon-side{
    position: relative;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
   /* background: #0f5b4952;
    border: 1px solid #b0ef96;*/
    /* border: 2px solid #699d7b; */
    border-radius: 7px;
}

.sidebar .nav-item{
    margin: 2px 0;
    border-radius: 0px;
    background: transparent;
    box-shadow: none;
    transition: all 0.3s ease;
}

.sidebar .nav-item a{
    display: flex;
    padding: 5px 15px;
    align-items: center;
    box-shadow: none;
    font-size: 16px;
    justify-content: flex-start;
    border-radius: 0px !important;
}

.sidebar .nav-item p{
    padding: 0;
    margin: 0;
    color: white;
    font-size: 12px;
    font-weight: 500;
    margin-left: 3px;
}

.sidebar .nav-item svg{
    width: 20px;
    height: 20px;
    object-fit: contain;
    fill: white;
}

.sidebar .button-send{
    position: relative;
    text-decoration: none;
    margin: auto;
    width: 90%;
    margin-bottom: 10px;
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 7px;
    padding-left: 1rem;
    padding-right: 1rem;
    border-width: 1px;
    outline-style: none;
    max-width: unset;
    border-radius: 10px;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 600;
    color: #000000;
    border-color: #b0ef96;
    background: #b0ef96;
    text-transform: uppercase;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
    border: 1px solid #b0ef96;
}

.sidebar .button-send div{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

.sidebar .button-send div p{
    margin:0;
    font-weight: 600;
    font-size: 14px;
}



.sidebar .button-send:hover{
    background-color: #6aaf4e;
}

.btn-mobile-top span{
    color: white;
    font-weight: 600;
} 

.btn-mobile-top{
    gap: 10px;
    border-radius: 10px;
    background: #3a7479b8;
    border: none;
    outline: 0px;
    height: 50px;
    color: white;
    width: 100%;
    padding-right: 0px;
    margin-right: 0px;
    transition: all 0.3s ease;
}

.btn-mobile-top:hover{
    background: #286b71e3;
}

.sidebar .button-send svg{
    margin-top: -3px;
    width: 20px;
    height: 20px;
}

.sidebar .button-send a, .sidebar .button-send p, .sidebar .button-send svg{
    color: #000;
    fill:#000;
}

.sidebar .info-user-drop{
    font-size: 12px;
    color: white !important;
}

.sidebar .nav-item-active:hover{
    background: #699d7b;
}

.sidebar .nav-item-actual{
    background: transparent;
}

.sidebar .nav-item-actual svg,
.sidebar .nav-item-actual svg path{
    fill:#b0ef96 !important;
}



.sidebar .link-sidebar strong{
    color: white;
}

.sidebar .nav-item-actual p,
.sidebar .nav-item-actual strong{
    color:#b0ef96;
}
/*
.sidebar .nav-item-actual{
    background: #9bcb87e6;
}*/

.sidebar .nav-item-inactive{
    opacity: 0.5;
    filter: brightness(0.8);
    cursor: auto;
}

.sidebar .dropdown-menu{
    position: absolute;
    bottom: 50px;
    left: 8px;
    display: flex;
    flex-direction: column;
    opacity: 0;
    z-index: -1000;
    transition: all 0.3s ease;
}

.sidebar .dropdown-menu:after{
    content: "";
    position: absolute;
    width: 100%;
    display: flex;
    height: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
    background-color: #ffffff00;
    z-index: 1000000000;
}

.sidebar .dropdown-menu-show{
    opacity: 1;
    z-index: 1000;
}

.sidebar .dropdown-menu-show:after{
    z-index: -100000000000;
}

.button-sidebar{
    position: relative;
    display: block;
    visibility: hidden;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    background: #fdfdfd00;
    border: none;
    z-index: 12;
}

.sidebar .info-user-drop button,
.sidebar .info-user-drop .link-sidebar {
    transition: all 0.3s ease;
}

.sidebar .info-user-drop button:hover,
.sidebar .info-user-drop .link-sidebar:hover{
    background: #699d7b !important;
}

.sidebar .dropdown-menu{
    background: #35696ecf;
    border: 1px solid #b0ef96;
    color: white !important;
    padding: 0;
    backdrop-filter: blur(10px);
}

.sidebar .dropdown-item{
    padding: 10px;
    text-align: center;
    color: white !important;
    transition: all 0.3s ease;
}

.sidebar .dropdown-item:hover{
    color: white;
    background: #43895eb3;
}

.bottom-mobile-options{
    position: fixed;
    display: flex;
    color: white;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #28575dde;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    padding: 0 20px;
    padding-top: 10px;
    padding-bottom: 8px;
    border-top: 1px solid #b0ef966b;
    border-radius: 10px 10px 0px 0px;
    width: 100%;
    z-index: 3;
}

.bottom-mobile-options .options{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-weight: 600;
    background: transparent;
    border-radius: 10px;
    max-width: 130px;
    text-align: center;
    transition: all 0.3s ease;
}
.bottom-mobile-options .options span,
.bottom-mobile-options .options svg{
    transition: all 0.3s ease;
    text-align: center;
}

.bottom-mobile-options .options:hover span,
.bottom-mobile-options .options:hover svg{
    color:#b0ef96;
    fill:#b0ef96 !important;
}

.bottom-mobile-options .container-options{
    width: 100%;
    font-size: 14px;
    gap: 10px;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.bottom-mobile-options span{
    color:white;
    margin-top: 3px;
}

@media(min-width:992px){
    .bottom-mobile-options{
        display: none;
    }
}

@media(max-width:992px){
    .sidebar-active{
        left:0;
    }

    .button-sidebar{
        visibility: visible;
    }
}

.dropdown-top-mobile{
    position: fixed;
    top: 81px;
    bottom: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    background-color: #2d535796;
    backdrop-filter: blur(3px);
    transition: all 0.3s ease;
    max-height: 0vh;
    overflow: hidden;
    z-index: 5;
}
.container-dropdown h4{
    margin-bottom: 1px;
    font-size: 18px;
    font-weight: 600;
}
.container-dropdown h5{
    font-size: 16px;
}

.container-dropdown h4, .container-dropdown h5{
    color:white;
} 

.dropdown-top-mobile .indicator-name{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    font-weight: 600;
    background: #b0ef96;
    border-radius: 100%;
}

.dropdown-top-mobile .button-dropdown{
    display: flex;
    width: 100%;
    max-width: unset;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-decoration: none;
}

.dropdown-top-mobile .nav-link{
    padding: 7px 4px;
    background-color: transparent;
    box-shadow: none;
    display: flex;
    border-radius: 10px;
    gap: 10px;
    align-items: center;
    transition: all 0.3s ease;
}

/*.dropdown-top-mobile .nav-link:hover{
    background: #43895eb3;
}*/

.dropdown-top-mobile .nav-link:hover{
    background: #699d7b;
}

.dropdown-top-mobile .nav-link p{
    margin: 0;
    color: white;
    font-weight: 600;
}

.dropdown-top-mobile .button-dropdown div{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap:4px;
}

.dropdown-top-mobile .button-dropdown div p{
    margin: 0;
}

.buy-exam-icon path{
    fill:#b0ef96;
}

.dropdown-top-mobile-active{
    max-height: 100vh;
}

.container-dropdown{
    padding: 0 20px;
}

@media(min-width:992px){
    .dropdown-top-mobile{
        display: none;
    }
    
    .sidebar{
        background: #48898F4A;
        backdrop-filter: blur(3px);
        position: relative;
        width: 280px;
        height: 100vh;
        left: 0;
    }

    .sidebar .info-user-drop button{
        width: 100% !important;
        transition: all 0.3s ease;
    }

   .nav-user{
        display: none !important ;
    }

    .sidebar .button-exit{
        display: none;
    }
}

