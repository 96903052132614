.container-page-support h1{
    font-size: 38px;
    font-weight: 600;
    color:#B0EF96 !important;
}

.container-page-support .container-section{
    padding: 0 30px;
    margin-top: 150px;
    color:white;
    margin-bottom: 80px;
}


.container-page-support .container-section ul{
    padding: 0;
    text-align: left;
    list-style: none;
}

.container-page-support .container-section ul li{
    text-align: center;
    font-size: 16px;

}

.container-page-support .container-section ul li a{
    color:white;
    text-decoration: none;
    font-weight: 600;
    transition: all 0.3s ease;
}

.container-page-support .content-support{
    max-width: 620px;
    margin: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 0px;
    display: flex;
    padding: 25px 20px;
    margin-bottom: 1.5rem;
    flex-direction: column;
    gap: 0.75rem;
    border-radius: 1.5rem;
    width: 100%;
    backdrop-blur: blur(8px);
    border-color: #4f969c;
    background: #48898F4A;
}

.container-page-support .container-section .icon-contact{
    width: 20px;
    height: 20px;
    margin-right: 5px;
    margin-top: -4px;
}

.container-page-support .container-section ul{
    display: flex;
    flex-direction: column;
    gap:7px;
}

.container-page-support .button-send-transparent{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 190px;
    padding: 2px 10px;
    height: 50px;
    text-decoration: none;
}

.container-page-support .button-send-transparent .whatsapp-icon{
    fill: white;
    filter: invert(1);
}

.container-page-support .button-send-transparent:hover .whatsapp-icon{
    filter: invert(0);
}

.container-page-support .button-send-transparent .whatsapp-icon path{
    fill: white;
}

.container-page-support .telegram-icon path{
    stroke: white;
    transition: all 0.3s ease;
}

.container-page-support .button-send-transparent:hover .telegram-icon path{
    stroke: black;
}

.container-page-support .container-form-contact .row{
    margin-top: 10px;
}

.container-page-support .container-form-contact label{
    font-size: 16px;
    font-weight: 600;
    color: white;
    margin-bottom: 4px;
}

.container-page-support .container-form-contact form{
    background: #48898F4A;
    box-shadow: none;
    border: 1px solid #B0EF96 !important;
    border-radius: 10px;
    padding: 20px;
}

.container-page-support .container-form-contact input,
.container-page-support .container-form-contact textarea{
    width: 100%;
    padding: .5rem;
    border-radius: 10px;
    outline: none;
    border: none;
    background-color: transparent;
    border: 1px solid #4f969c;
    color: rgba(255, 255, 255, 0.692);
    transition: all 0.3s ease;
}

.container-page-support .container-form-contact input::placeholder,
.container-page-support .container-form-contact textarea::placeholder{
    color: rgba(255, 255, 255, 0.692);
}

.container-page-support .container-form-contact input:focus,
.container-page-support .container-form-contact textarea:focus{
    border: 1px solid #B0EF96;
    outline: none;
}

.container-page-support .container-form-contact h2{
    font-size: 26px;
}



@media(max-width:992px){
    .container-page-support .container-section,
    .container-page-support .container-section ul li{
        text-align: center;
    }

    .container-page-support .container-form-contact label{
        text-align: left;
    }
       
}
