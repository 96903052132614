.navbar{
    background-color: rgba(45, 83, 87, 0.5);
    backdrop-filter: blur(4px);
    padding: 0 5px;
    padding-top: 0px;
    padding-bottom: 0px;
    border-bottom: 1px solid #b0ef966b;
    border-radius: 10px;
}

.navbar .nav-item{
    outline: 0;
    border: none;
}

.container-navbar-mobile{
    margin: auto;
    display: flex;
    flex-direction: column;
    height: max-content;
    justify-content: flex-start;
}


@media(max-width:992px){
    .navbar-session .navbar-toggler{
        margin-left: auto;

    }

    .start-btn-desktop{
        display: none;
    }

    .start-btn-mobile{
        display: flex;
    }

    .navbar-session .user-email{
        margin-left: 0;
    }

    .container-mobile-nav{
        display: grid !important;
        grid-template-columns: 1fr auto auto;
        gap: 8px;
    }

    .navbar-toggler {
        margin-right: 6px !important;
        margin-left: auto;
    }

    .started-btn{
        margin-left: 0 !important; 
    }

    .nav-btns{
        display: flex;
        width: 100%;
        /* height: 40px; */
        justify-content: center;
        flex-direction: row;
        flex-flow: wrap;
        margin-top: 50px;
    }

    .ul-bg{
        padding: 0 !important;
        gap: 10px;
        margin-right: 0px !important;
        margin-left: 0 !important;
        margin: 0 !important;
    }

    .user-email-inner{
        margin: 0;
        margin-left: 0 !important;
    }
}

@media(max-width:478px){
    .navbar{
        padding-top: 22px !important;
        padding-bottom: 22px !important;
    }
}
@media(min-width:992px){
    .navbar{
        padding: 0 25px;
    }

    .container-navbar-mobile{
        margin-top: 0;
        flex-direction: row;
        width: 100%;
    display: flex;
    justify-content: flex-end;
    }

    .start-btn-desktop{
        display: flex;
    }

    .start-btn-mobile{
        display: none;
    }
    
}
/* Remove border from toggler */
.navbar-toggler {
    border: 0 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none !important;
    box-shadow: none !important;
    border: 0 !important;
}

/* Lines of the Toggler */
.toggler-icon{
    width: 20px;
    height: 2px;
    background-color: white;
    display: block;
    transition: all 0.2s;
}

.middle-bar{
    margin: 4px;
}

/* State when navbar is opened (START) */
.navbar-toggler .top-bar {
    transform: rotate(50deg);
    transform-origin: 10% 10%;
}

.navbar-toggler .middle-bar {
    opacity: 0;
    filter: alpha(opacity=0);
}

.navbar-toggler .bottom-bar {
    transform: rotate(-48deg);
    transform-origin: 10% 90%;
}

/* State when navbar is collapsed (START) */
.navbar-toggler.collapsed .top-bar {
    transform: rotate(0);
}

.navbar-toggler.collapsed .middle-bar {
    opacity: 1;
    filter: alpha(opacity=100);
}

.navbar-toggler.collapsed .bottom-bar {
    transform: rotate(0);
}

/* Color of Toggler when collapsed */
.navbar-toggler.collapsed .toggler-icon {
    background-color: white;
}
.navbar-nav{
    margin-right: 12px;
    padding-right: 40px;
}

/*  */
.container {
    width: 100%;
  }
.logo{
    max-width: 180px;
    width: 100%;
    object-fit: contain;
    height: 80px;
    margin-left: 0;
}
.ul-bg{
    padding-right: 20px;
    padding-left: 20px !important;
}
.started-btn{
    display: none;
}
.login-btn{
    background-color: #48898f;
    color: white;
    border: none;
    outline: none;
    padding: 5px 20px;
    border-radius: 50px;
    min-height: 48px;
    font-weight: 600;
    font-size: 16px;
    transition: all 0.3s ease;
}
.login-btn:hover{
    opacity: .8;
}
.logout-btn{
    background-color: #48898f;
    color: white;
    border: none;
    outline: none;
    height: 48px;
    padding: 5px 20px;
    border-radius: 50px;
    font-size: 16px;
    font-weight: bold;
}
.logout-btn:hover{
    opacity: .7;
}
/*  */
.btn{
    background-color: #B0EF96;
    color: #2D5357;
    font-weight: bold;
    padding: 6px 20px;
    border-radius: 100px;
    font-size: 16px;
    min-height: 48px;
    cursor: pointer;
    box-shadow: 0 0 1rem 0 #B0EF96;
    transition: all 0.3s ease;;
}
/*  */
.btn:hover {
    background-color: #B0EF96 !important;
    color: #2D5357;
    box-shadow: 0 0 0rem 0 #B0EF96;
}
li{
    font-size: 20px;
}
li>a:hover{
    color: #B0EF96 !important;
}
.ul-bg{
    padding: 2px 0px 3px 0px;
    justify-content: center;
    align-items: center;
    gap: 30px;
    border-radius: 50px;
}
.navbar-dark .navbar-nav .nav-link {
    font-size: 18px;
    color: white;
    text-align: center;
}
.nav-btns{
    display: flex;
    gap: 15px;
    align-items: center;
}
.user-email{
    /*background-color: #48898f;*/
    padding: 5px;
    color:#2D5357 !important;
    border-radius: 10px;
    font-size: 10px;
}
/* animation keyframes */
  @keyframes bg-spin {
    to {
      --border-angle: 1turn;
    }
  }
  @property --border-angle {
    syntax: "<angle>";
    inherits: true;
    initial-value: 0turn;
  }

/* Responsivness */
@media screen and (max-width:1024px){
    .navbar-nav{
        margin-right: 1.5rem !important;
    }
    .ul-bg{
        gap: 5px;
    }
    .btn{
        padding: 8px 22px;
        border-radius: 100px;
        font-size: 13px;;
    }
    .login-btn{
        padding: 8px 22px;
        font-size: 13px;
    }
}

@media(min-width:992px){
    .user-email{
        padding: 6px 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        height: 48px;
        color: white;
        font-size: 16px;
        border-radius: 35px;
    }

    
}
@media screen and (max-width:993px){
    .started-btn{
        display: block;
        background-color: #48898f;
        color: white;
        border: none;
        outline: none;
        padding: 5px 20px;
        border-radius: 50px;
        font-size: 18px;
        font-weight: bold;
        margin-left: auto;
    }
  /*  .login-btn{
        display: none;
    }
    .btn{
        display: none;
    }*/
    .user-email{
        margin-left: auto;
        margin-right:0 !important;
        background-color: #B0EF96;
        color: #2d5357 !important;
        font-weight: bold;
        border: none;
        margin-right: 10px;
        outline: none;
        height: 40px;
        display: flex;
        justify-content: center;
        gap: 12px;
        align-items: center;
        padding: 4px 12px;
        border-radius: 100px;
        font-size: 16px;
        cursor: pointer;
        box-shadow: 0 0 1rem 0 #B0EF96;
    }
    .navbar-collapse {

        display: flex;
        height: 92vh !important;
        justify-content: center;
        align-items: center;
    }
        /*.ul-bg{
            gap: 15px;
            box-shadow: none;
            margin-top: -100px;
            border-radius: 50px;
            padding-right: 15px;
            padding-left: 15px !important;
            text-align: center;
        }*/

        .navbar-dark .navbar-nav .nav-link, .login-btn, .btn{
            font-size: 18px !important;
        }
}
@media screen and (max-width:768px){
    .logo{
        width: 170px;
        height: 60px;
    }


    .container {
        max-width: 1140px; /* Adjust max-width as needed */
        margin: 0 auto;
      }
    .logo{
        font-size: 30px;
    }
    .nav-btns{
        display: flex;
    }
    .navbar-nav{
        margin-right: 0;
    }
    .navbar{
        backdrop-filter: blur(50px);
    }
    /*.ul-bg{
        gap: 15px;
        box-shadow: none;
        margin-top: -100px;
        border-radius: 50px;
        padding-right: 15px;
        padding-left: 15px !important;
    }*/
    li{
        text-align: center;
    }
    /* p{
        text-align: center;
    } */
}

@media(max-width:498px){
    .footer .list-link a{
        font-size: 15px !important;
    }
}
@media screen and (max-width:426px){

    .logo{
        width: 150px;
        height: 40px;
        object-fit: contain;
    }
    /*.ul-bg{
        gap: 15px;
        box-shadow: none;
        border-radius: 50px;
        padding-right: 15px;
        padding-left: 15px !important;
    }*/
    li{
        text-align: center;
    }
    li{
        text-align: center;
    }
}
@media screen and (max-width:376px){

    .logo{
        width: 160px;
        height: 55px;
    }
    .ul-bg{
        gap: 15px;
        box-shadow: none;
        border-radius: 50px;
        padding-right: 15px;
        padding-left: 15px !important;
    }
    li{
        text-align: center;
    }
}
@media screen and (max-width:320px){
    .logo{
        width: 150px;
        height: 50px;
    }

    .ul-bg{
        gap: 15px;
        box-shadow: none;
        border-radius: 50px;
        padding-right: 15px;
        padding-left: 15px !important;
    }
    .started-btn{
        padding: 2px 8px;
        font-size: 10px;
    }
    /* p{
        text-align: center;
    } */

}


@media screen and (min-width:993px) and (max-width:1173px){
    .navbar-nav{
        margin-right: auto;
        gap: 15px;
        margin-left: 0 !important;
    }
}

@media(max-width:478px){
    .started-btn{
        font-size: 15px;
    }

    .logo{
        width: 124px;
    height: 30px;
    object-fit: contain;
    }
}

@media(max-width:407px){

    .logo{
        margin-left: 0;
    }

    .started-btn{
        font-size: 13px;
        margin-left: 0px;
    }

    .navbar-toggler {
        padding: 0;
    }

}


/*Break points to fix erros*/

@media(max-width:1190px){
    .ul-bg{
        gap: 10px;
        margin-right: 5px;
        margin-left: 0;
    }

    .navbar-expand-lg .navbar-collapse{
        justify-content: flex-end;
    }
}

@media(max-width:992px){
    .navbar-expand-lg .navbar-collapse{
        justify-content: center;
        font-size: 18px;
        flex-direction: column;
    }
}

@media(max-width:1255px){
    .navbar-brand{
        margin-right: 7px;
    }
    .navbar-dark .navbar-nav .nav-link{
        font-size: 16px;
    }
}

@media(max-width:1081px){
    .navbar-dark .navbar-nav .nav-link,
    .login-btn,
    .btn{
        font-size: 14px;
    }
}

@media(min-width:992px){
    .navbar-brand{
        width: 16%;
    }
}