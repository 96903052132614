.section-checkout {
  margin: auto;
  margin-top: 0px;
  display: flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 1.5rem;
  flex-direction: column;
  gap: 0.75rem;
  border-radius: 1.5rem;
  width: 100%;
  backdrop-blur: blur(8px);
  border-color: #4f969c;
  background: #48898f4a;
  border-color: 1px;
}

.checkout-popup .field-popup input{
    margin-top: 5px;
    width: 100%;
    padding: .5rem;
    border-radius: 10px;
    outline: none;
    border: none;
    background-color: transparent;
    border: 1px solid #4f969c;
    color: rgba(255, 255, 255, 0.692);
    transition: all 0.3s ease;

}

.checkout-popup .eye-icon{
  position: absolute;
  right: 10px;
  top: 16px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.checkout-popup .field-popup input::placeholder{
  color: rgba(255, 255, 255, 0.8);
}

.checkout-popup .field-popup input:focus{
  border: 1px solid #B0EF96;
  outline: none;
}

.input-coupon::placeholder {
  color: white;
}

.input-coupon {
  outline: 0;
  border-radius: 10px;
  padding: 7px;
  resize: none;
  background: #285c64cc;
  color: white !important;
  border: 1px solid #4f969c;
  transition: all 0.3s ease;
}

.section-checkout .content-login-label {
  border-radius: 10px;
  gap: 15px !important;
}
.section-checkout .select-country {
  display: block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 0.5rem;
  border-width: 1px;
  outline-style: none;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #ffffff;
  background-color: white;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  transition-duration: 300ms;
  border-color: #4f969c;
}

.section-checkout .select-country option{
    color: white !important;
}

.section-checkout .content-login-label {
  border: 1px solid #b0ef96;
}

.section-checkout .content-coupon-label {
  position: relative;
  overflow: hidden;
  border: 1px solid #dffbd382 !important;
}

.section-checkout .content-billing div {
  margin-top: 0px;
 /* display: flex;
  gap: 0.35rem;
  justify-content: flex-start;*/
  align-items: start;
  border-radius: 10px;
}

.section-checkout input:focus,
.section-checkout select:focus {
  border-color: #b0ef96 !important;
}

input {
  outline: 0 !important;
}

.section-checkout .content-billing div p,
.content-login-label p {
  font-size: 16px;
  color: white;
}

.section-checkout .content-billing div a,
.content-login-label a {
  color: #b0ef96;
  font-size: 14px;
  text-decoration: none;
}

.section-checkout .form-billing {
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: 0px;
  flex-direction: column;
  gap: 0.75rem;
  justify-content: center;
  align-items: center;
  border-width: 1px;
  border-color: #4f969c;
  border-radius: 10px;
}

.form-billing h3,
.section-checkout .title {
  width: 100%;
  font-size: 16px;
  line-height: 1.75rem;
  font-weight: 600;
  color: #ffffff;
}
.content-login-label {
  display: flex;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  gap: 0.75rem !important;
  justify-content: flex-start;
  align-items: center;
}
.group-inputs {
  display: flex;
  gap: 0.75rem;
  width: 100%;
}

.group-inputs div {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.group-inputs label {
  font-weight: 600;
  color: #ffffff;
}

.section-checkout .form-inputs {
  display: block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 0.5rem;
  border-width: 1px;
  outline-style: none;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #ffffff;
  border: 1px solid #4f969c;
  background-color: transparent;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  transition-duration: 300ms;
  border-color: #4f969c;
}

.section-checkout .form-inputs::placeholder {
  color: white;
}

.section-checkout .container-input-field label {
  font-weight: 600 !important;
  font-size: 16px;
  margin-bottom: 7px;
}

.select-inputs {
  display: block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 0.5rem;
  border-width: 1px;
  outline-style: none;
  height: 38px;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #ffffff;
  background-color: transparent;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  background: #285c64cc;
  color: white !important;
  border: 1px solid #4f969c;
}

.select-input-currency {
  background: #285c64;
}

.container-input-field {
  display: flex;
  flex-direction: column;

}

.container-order-data {
  display: flex;
  margin-top: 0.75rem;
  flex-direction: column;
  gap: 0.75rem;
}

.container-order-content {
  padding: 1rem;
  margin-top: 0.75rem;
  border-radius: 0.5rem;
  border-width: 1px;
  width: 100%;
  backdrop-blur: blur(8px);
  border-color: #4f969c;
  background: #48898f4a;
  border-color: #4f969c;
  background: #48898f4a;
}

.section-checkout .container-order-data .title {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 600;
  color: #ffffff;
}

.content-information-checkout{
/*  border-color: #4f969c;
  background: #48898f4a;
 */ 
 padding: 10px 0px;
  border-radius: 10px;
  margin-bottom: 0px;
  color: white;
}

.content-information-checkout h3{
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}

.text-order {
  display: flex;
  align-items: center;
  margin-top: 0.25rem;
  justify-content: space-between;
  width: 100%;
  font-size: 16px;
  color: #e5e7eb;
}

.text-order span{
  font-size: 26px;
    color: #b0ef96;
}

.container-paymethods {
  display: flex;
  margin-top: 0.5rem;
  flex-direction: column;
  gap: 0.75rem;
  width: 100%;
}

.container-paymethods h4 {
  margin-bottom: 0px;
  font-size: 16px;
  line-height: 1.75rem;
  font-weight: 600;
  color: #ffffff;
}

.form-card {
  display: flex;
  padding: 0.75rem;
  flex-direction: column;
  gap: 0.75rem;
  border-radius: 0.5rem;
  background: #23565fc9;
}

.form-crypto {
  display: flex;
  padding: 0.75rem;
  flex-direction: column;
  gap: 0.75rem;
  border-radius: 0.5rem;
  background: #23565fc9;
}

.form-crypto label {
  margin-bottom: 0.25rem;
  font-weight: 600;
  color: #ffffff;
}

.section-checkout .field-checks {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: flex-start;
  width: 100%;
}

.section-checkout .field-checks label {
  padding: 0;
  margin: 0;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #ffffff;
  max-width: max-content;
}

.section-checkout .field-checks label a {
  text-decoration: none;
}

.section-checkout .button-send {
  position: relative;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 1rem;
  border-width: 1px;
  outline-style: none;
  max-width: unset;
  width: 100%;
  border-radius: 10px;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
  color: #000000;
  border-color: #b0ef96;
  background: #b0ef96;
  text-transform: uppercase;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  border: 1px solid #b0ef96;
}

.section-checkout .button-show-fields {
  transition: all 0.3s ease;
  border-radius: 10px;
  margin-top: 3px;
  background: #b0ef96;
  height: 24px;
  width: 38px;
  border: none;
  outline: 0;
  transition: all 0.3s ease;
}

.section-checkout .button-send:hover,
.section-checkout .button-show-fields:hover {
  background-color: #6aaf4e;
}
/*
.button-send:hover {
    color: #000000; 
   border-color: #b0ef96;
   background: #b0ef96;
    }
*/
@media (min-width: 992px) {
  .section-checkout {
    flex-direction: row;
    justify-content: space-between;
  }
}

.content-billing {
  width: 100%;
}

@media (min-width: 992px) {
  .content-billing {
    width: 60%;
  }
  .group-inputs {
    justify-content: space-between;
  }
  .container-order-data {
    margin-top: 0;
  }

  .container-order-content {
    margin: 0;
    margin-top: 0;
  }

  .button-send {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 992px) {
  .section-checkout .field-checks label {
    max-width: 420px;
  }
}

@media (min-width: 1200px) {
  .section-checkout .content-coupon-label .button-send {
    max-width: 145px;
    font-size: 13px;
  }
}



/*Accordion contents*/

.accordion-contents .accordion{
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.accordion-contents .accordion-button{
  color: white;
  background-color: transparent;
}

/*.accordion-button:hover{
  border: 1px solid #B0EF96;
}*/
.accordion-contents .accordion-button:not(.collapsed){
  background-color: #346366;
  border: 1px solid #B0EF96;
  color: white;
}
/*.accordion-button:focus{
  border-color: #B0EF96 !important;
  box-shadow: none;
}*/
.accordion-contents .accordion-button:not(.collapsed)::after{
  background-image: url('../../assets/x.png');
  filter: invert(1);
}

.accordion-contents .acoordion-item, .accordion-contents .accordion-item:first-of-type,
.accordion-contents .accordion-item:last-of-type{
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
.accordion-contents .accordion-button:focus{
  border-color:transparent;
  box-shadow:none;
}

.accordion-contents .accordion-item{
  border-radius: 10px !important;
  transition: all 0.3s ease;
}
.accordion-contents .accordion-button::after{
  filter: invert(1);
}

.accordion-contents .accordion-body{
  border-radius: 0px !important;
  background: transparent !important;
}

.accordion-contents .accordion-item:hover{
  border-color:#b0ef96;
}

.accordion-contents .accordion-button:not(.collapsed){
  border: transparent !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.accordion-contents .accordion-body{
  border: none !important;
}

.accordion-contents .accordion-item:not(:first-of-type){
  border-top: 1px solid #4f969c;
}

.accordion-contents .accordion-item:has(.show){
  border-color:#b0ef96 !important;
}

.accordion-contents .accordion-item:hover:not(:first-of-type),
.accordion-contents .accordion-item:focus:not(:first-of-type)
.accordion-contents .accordion-item:last-of-type:hover,
.accordion-contents .accordion-item:last-of-type:focus{
  border-color:#b0ef96;
}

.accordion-contents .accordion-item:first-of-type>.accordion-header .accordion-button{
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.accordion-contents .accordion-item:last-of-type>.accordion-header .accordion-button.collapsed{
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.accordion-contents .accordion-item{
  border-color: #4f969c;
  background: #48898f4a;
  color: white;
  width: 100%;
  font-size: 16px;
}
.accordion-contents .accordion-body{
  font-size: 16px;
  border-radius: 0px;
  border: 1px solid #B0EF96;
  background-color: #346366;
}

.accordion-contents .accordion-body ul{
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.accordion-contents .accordion-body > ul > li{
  font-size: 16px;
  text-align: left;
}

.accordion-contents .accordion-button{
  font-weight: 600;
  font-size: 16px;
}

.accordion-contents .accordion-button{
  gap:10px;
  padding: 23px 22px !important;
}