.container-funded-accounts .container-information{
  border-color: #4f969c;
  background: #48898F4A;
  border-radius: 10px;
  padding: 30px 20px;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.container-funded-accounts{
    color:white;
    text-align: center;
}

.container-funded-accounts .container-information ul{
    display: flex;
    flex-direction: column;
    gap: 14px;
    list-style: none !important;
    padding: 0 20px;
}

.container-funded-accounts .container-information ul svg{
    width: 20px;
    height: 20px;
    fill: #b0ef96;
    margin-top: -1px;
    margin-right: 5px;
}

.container-funded-accounts .container-information ul  li{
    display: flex;
    align-items: flex-start;
    font-size: 16px;
    text-align: left;
}

.container-funded-accounts .container-information h3{
    margin-top: 16px;
    margin-bottom: 10px;
    font-size: 23px;
    font-weight: 600;
}

.container-funded-accounts .container-information h4{
    font-size: 19px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 20px;
}

.container-funded-accounts .container-information p{
    font-size: 14px;
}

.container-funded-accounts .container-information a{
    text-decoration: none;
}