.container-success{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.container-success .content{
    border-radius: 10px;
    padding: 20px;
    border: 1px solid #b0ef96 !important;
}

.container-success h3, .container-success p{
    text-align: center;
    color: white;
}
.container-success .content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container-success .button-link{
    position: relative;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 1rem;
    border-width: 1px;
    outline-style: none;
    width: 100%;
    border-radius: 10px;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 600;
    color: #ffffff;
    background-color: transparent;
    text-transform: uppercase;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
    text-align: center;
    text-decoration: none;
    border: 1px solid #b0ef96;
}

.container-success .button-link:hover{
    color: #000000;
    border-color: #b0ef96;
    background: #b0ef96;
}