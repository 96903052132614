
@font-face {
  font-family: 'PlusJakartaSans';
  src: url('./assets/fonts/PlusJakartaSans-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'PlusJakartaSans';
  src: url('./assets/fonts/PlusJakartaSans-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'PlusJakartaSans';
  src: url('./assets/fonts/PlusJakartaSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: 'PlusJakartaSans';
  src: url('./assets/fonts/PlusJakartaSans-Bold.ttf') format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'PlusJakartaSans';
  src: url('./assets/fonts/PlusJakartaSans-ExtraBold.ttf') format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Light.ttf') format('truetype');
  font-weight: lighter;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'PlusJakartaSans';
  src: url('./assets/fonts/PlusJakartaSans-Light.ttf') format('truetype');
  font-weight: lighter;
}

body {
  margin: 0;
  font-family:'PlusJakartaSans', system-ui, sans-serif; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #2D5357;
  position: relative;
  background-size: cover;

  padding-top: 70px;
}

body::before{
  content:"";
  position: absolute;
  top: -40px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  min-height: 100vh;
  padding-top: 40px;
  background-size: cover;
  background-image: url("./assets/heavy-stars.svg"), url("./assets/accent-grid.png"),url("./assets/sparse-stars.svg");
  opacity: 0.50;
  z-index: -1;
}


p{
  font-family:'Inter', system-ui, sans-serif; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.button-send {
  position: relative;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-width: 1px;
  outline-style: none;
  width: 100%;
  border-radius: 10px;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
  color: #000000;
  border-color: #b0ef96;
  background: #b0ef96;
  text-transform: uppercase;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  border: 1px solid #b0ef96;
  max-width: 350px;
}

.button-send:hover{
  background-color: #6aaf4e;
}


.button-send-transparent {
  position: relative;
  margin: 0;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-width: 1px;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
  color: #ffffff;
  background: transparent;
  border-radius: 10px;
  text-transform: uppercase;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  width: 160px;
  outline: 0 !important;
  border: 1px solid #b0ef96;
}

.button-send-transparent:hover {
  color: #000000;
  border-color: #b0ef96;
  background: #b0ef96;
}


.container-app-view{
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.container-content-app{
  width: 100%;
  overflow: auto;
  width: 100%;
  padding: 20px;
  overflow: auto;
}

.breadcrumb-item:hover{
  color: #B0EF96 !important;
}

.loader-mod:after,
.lds-dual-ring:after {
  border-color: #b0ef96 white #b0ef96 white!important;
}

@media(max-width:992px){
  .container-content-app{
    padding-bottom: 80px;
  }
}



/*Sweet Alert*/

.swal2-container .swal2-popup {
  display: grid !important;
  opacity: 1 !important;
  background: #206369 !important;
  color: white !important;
  border: 2px solid #9bfb8a !important;
  backdrop-filter: blur(100px) !important;
}

.swal2-container .swal2-confirm{
  display: inline-block;
    background: #26b16624;
    border: 2px solid #9bfb8a;
    padding: 8px 34px;
    border-radius: 10px;
    transition: all 0.3s ease;
}

.swal2-container .swal2-deny {
  border-radius: 10px;
}



.title-dashboard-user{
  font-size: 24px;
  color: white;
}

.section-content-page{
  border-color: #4f969c;
  background: #48898F4A;
  border-radius: 10px;
  padding: 15px 10px;
  padding-bottom: 20px;
}