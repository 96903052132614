.container-funded-withdraw .container-info-withdraw{
    margin: auto;
    margin-top: 0px;
    display: flex;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 1.5rem;
    flex-direction: column;
    border-radius: 1.5rem;
    width: 100%;
    backdrop-blur: blur(8px);
    border-color: #4f969c;
    background: #48898f4a;
    color:white
}
.container-funded-withdraw .title-content{
    color: white;
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 20px;
}


.container-funded-withdraw  .container-info-withdraw .container-table,
.container-funded-withdraw .container-table-content{
    display: none;
    grid-template-columns: repeat(5,1fr);
    transition: all 0.3s ease;
    cursor: pointer;
}

.container-funded-withdraw .container-table-content:hover{
    background-color: #145543ad;
}

.funded-withdraw-account .container-info-withdraw .container-table,
.funded-withdraw-account .container-table-content{
    grid-template-columns: repeat(4,1fr);
    font-size: 14px;
}

.container-funded-withdraw  .container-info-withdraw .container-table p,
.container-funded-withdraw .container-table-content div p{
    margin: 0;
}

.container-funded-withdraw  .container-info-withdraw .container-table div{
    border: 1px solid #699d7b;
    background: #189f473b;
    font-weight: 600;
    padding: 5px 7px;
}

.container-funded-withdraw  .container-info-withdraw .container-table button{
    border: none;
    background-color: transparent;
    padding: 0;
    padding-left: 4px;
    outline: 0;
}

.container-funded-withdraw .container-table-content div{
    border: 1px solid #699d7b;
    font-weight: 600;
    padding: 5px 7px;
}

.container-funded-withdraw  .content-withdraw {
    display: flex;
    flex-direction: column; 
    border: 1px solid #7cb17d;
    background: #32655e75;
    border-radius: 10px;
    transition: all 0.3s ease;
   
  }

  .container-funded-withdraw  .content-withdraw:hover{
    background-color: #10574d75;
  }
  .container-funded-withdraw  .content-withdraw div:last-of-type{
    border: none;
  }
  .container-funded-withdraw  .content-withdraw div{
    padding: 5px 7px;
    
  }

  .container-funded-withdraw  .content-withdraw div hr{
    margin: 0;
    color: white;
    background: white;
    border-color: white;
    opacity: 1;
  }

  .container-funded-withdraw  .content-withdraw span{
    font-weight: 800;
  }

  .container-funded-withdraw  .content-withdraw div p{
    margin: 0;
  }

  .funded-withdraw-account .container-table-mobile{
    display: flex;
    flex-direction: column;
    font-size: 15px;
    gap:12px;
  }

  .container-funded-withdraw .id-withdraw { grid-area: id-withdraw; }
  .container-funded-withdraw .date-withdraw { grid-area: date-withdraw; }
  .container-funded-withdraw .amount-withdraw { grid-area: amount-withdraw; }
  .container-funded-withdraw .status-withdraw { grid-area: status-withdraw; }
  .container-funded-withdraw .address-withdraw { grid-area: address-withdraw; }
  
  .container-funded-withdraw .container-table-mobile .content-withdraw{
    display: grid;
    grid-template-areas: 
    "id-withdraw amount-withdraw"
    "date-withdraw status-withdraw"
    "address-withdraw address-withdraw"
    ". ."; 
  }
  
.content-check-desktop label{
    display: flex;
}
.funded-withdraw-account .show-text-desk{
    display: flex;
    margin-bottom: 3px;
}
.funded-withdraw-account .hidden-text-desk{
    display: none;
}

@media(min-width:1024px){
    .container-funded-withdraw .container-table-mobile{
        display: none;
    }

   /* .funded-withdraw-account .container-table-mobile{
        display: flex;
        flex-direction: column;
        gap:10px;
    }*/

    .container-funded-withdraw  .container-info-withdraw .container-table,
    .container-funded-withdraw .container-table-content{
        display: grid;
    }

    .funded-withdraw-account .container-info-withdraw .container-table,
    .funded-withdraw-account .container-table-content{
    }

    
    .content-check-desktop label{
        display: none;
    }

    .content-check-desktop .show-label{
        display: flex;
    }
}

