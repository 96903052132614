.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.breadcrumb{
  display: flex;
  flex-flow: wrap;
  border-color: #4f969c;
  padding: 5px 0px;
  border-radius: 10px;
  width: 100%;
}

.breadcrumb-item+.breadcrumb-item::before{
  color:white;
}

.breadcrumb-item.active{
  font-weight: 600;
  font-size: 13px;
  color: #B0EF96 ;
}

.breadcrumb-item, .breadcrumb-item a{
  color: #d1d1d1;
  transition: all 0.3s ease;
  font-size: 13px;
  text-decoration: none;
  font-weight: 600;
  
}

.breadcrumb-item svg{
  width: 14px;
  height: 14px;
  fill: white;
}

.breadcrumb-item a{
  display: flex;
  align-items: center;
  gap: 5px;
}




/*Loaders css*/

.lds-dual-ring {
  /* change color here */
  color: #1c4c5b
}
.lds-dual-ring,
.lds-dual-ring:after {
  box-sizing: border-box;
}
.lds-dual-ring {
  display: inline-block;
  width: 59px;
  height: 59px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6.4px solid currentColor;
  border-color: currentColor transparent currentColor transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}