.container-section-tickets .title-section{
    font-size: 38px;
    font-weight: 600;
    margin: 0;
    color: #B0EF96 !important;
}

.container-section-tickets .select-users{
    display: block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 0.5rem;
    border-width: 1px;
    outline-style: none;
    margin-top: 5px;
    height: 38px;
    width: 100%;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #ffffff;
    background-color: transparent;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
    background: #285c64cc;
    color: white !important;
    border: 1px solid #4f969c;
}

.container-section-tickets p{
    font-size: 16px;
    color:white;
    margin: 0;
}

.container-page-support .button-send-transparent{
    height: max-content;
    padding: 13px 10px;
}

.container-page-support .title-tickets{
    font-size: 24px;
    font-weight: 600;
    color: white;
}

.container-page-support .container-tickets{
    display: none;
}

.container-page-support .container-tickets-mobile{
    display: grid;
    grid-template-columns: 1fr ;
    gap: 10px;
}

.container-page-support .container-tickets-mobile h2{
    font-size: 18px;
    font-weight: 600;
    color:white;
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.container-page-support .container-tickets-mobile .ticket-title{
    border-bottom: 2px solid #b0ef9687;
    padding-bottom: 1px;
    margin-bottom: 7px;
}

.container-page-support .container-tickets-mobile p{
    margin: 0;
}

.container-section-tickets .section-tickets{
    padding: 0 30px;
}

.container-page-support .ticket{
    border: 1px solid #b0ef96a8;
    background: #19513c33;
    border-radius: 10px;
    padding: 10px 15px;
    transition: all 0.3s ease;
    cursor: pointer;
}

.container-page-support .label-ticket{
    font-size: 16px;
    font-weight: 600;
    color: #e7e7e7;
    margin: 0;
}

.container-page-support .ticket:hover{
    background-color: #04372436;
}

.modal-custom-ticket-create input,
.modal-custom-ticket-create textarea{
    width: 100%;
    padding: .5rem;
    border-radius: 10px;
    outline: none;
    border: none;
    background-color: transparent;
    border: 1px solid #4f969c;
    color: rgba(255, 255, 255, 0.692);
    transition: all 0.3s ease;
}

.modal-custom-ticket-create textarea{
    resize: none;
    height: 140px;
}

.modal-custom-ticket-create label{
    font-size: 16px;
    font-weight: 600;
    color: white;
    margin-top: 5px;
    margin-bottom: 5px;
}

.modal-custom-ticket-create input:focus,
.modal-custom-ticket-create textarea:focus{
    border: 1px solid #B0EF96;
    outline: none;
}

.modal-custom-ticket-create input::placeholder,
.modal-custom-ticket-create textarea::placeholder{
    color: whitesmoke;
}

.modal-custom-ticket select{
    display: block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 0.5rem;
    border-width: 1px;
    outline-style: none;
    margin-top: 5px;
    height: 38px;
    width: 100%;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #ffffff;
    background-color: transparent;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
    background: #285c64cc;
    color: white !important;
    border: 1px solid #4f969c;
}

@media(min-width:1024px){
    .container-page-support .container-tickets-mobile{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }
  
}


.modal-custom-ticket{
    display: flex !important;
}

.modal-custom-ticket .modal-dialog{
    margin: auto;
    width: 100%;
    padding: 20px 10px;
}

.modal-custom-ticket .modal-header{
    background: #206369de;
}

.modal-custom-ticket .modal-title{
    font-size: 19px;
}

.modal-custom-ticket .modal-footer{
    background: #206369de;
}

.modal-custom-ticket .modal-content{
    background: #206369de;
    color: white;
}

.modal-custom-ticket .btn-close{
    --bs-btn-close-color:white;
    filter: invert(1);
    outline: 0 !important;
    border: none;
    opacity: 1;
}

.modal-custom-ticket .modal-body h3{
    margin: 0;
    font-size: 16px;
    font-weight: 600;
}


.modal-custom-ticket .hide-message{
    display: none;
}

.modal-custom-ticket .modal-body .video-title{
    font-size: 18px;
    text-align: left;
    margin-bottom: 15px;
}

.container-page-support .ticket .label-buyer{
    background: #b0ef96;
    color: black;
    font-weight: 500;
    padding: 2px 6px;
    width: max-content;
    font-size: 14px;
}