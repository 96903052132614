.choose-package-section,
.choose-package-section .list-trade{
    padding: 0;
}

.table-list-packages .rows-active,
.table-list-packages .rows-inactive{
    width: 100%;
    border-radius: 0px !important;
}

.table-list-packages  div{
    border-radius: 0px !important;
}

.choose-package-section .table-list-packages .row-text{
    line-height: 17px;
    padding-left: 31px !important;
}

.table-list-packages .rows-active, 
.table-list-packages .rows-inactive{
    align-items: center !important;
}

.table-list-packages .fade-content{
    top:-4px;
}

.table-list-packages .accordion-item-list{
    width: 100%;
}

.section-price-account .table-list-packages .row-text::after{
  content: '';
  border-radius: 3px;
  border: 1px solid #a767b3;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  margin: auto;
  margin-left: 0px;
  left: -5px !important;
  transform: rotate(0deg) !important;
  filter: invert(1);
  width: 24px !important;
  height: 20px !important;
  position: absolute;
 
}

.section-price-account  .table-list-packages .row-text::before{
  content: '';
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  margin: auto;
  margin-left: 0px;
  left: -2px;
  transform: rotate(270deg);
  filter: invert(1);
  width: 18px;
  height: 22px;
  position: absolute;
  transition: all 0.3s ease-in-out;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 10px;
  background-image: url("data:image/svg+xml,%3C%3Fxml version=%271.0%27 encoding=%27iso-8859-1%27%3F%3E%3C%21-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --%3E%3Csvg fill=%27%23000000%27 height=%27800px%27 width=%27800px%27 version=%271.1%27 id=%27Layer_1%27 xmlns=%27http://www.w3.org/2000/svg%27 xmlns:xlink=%27http://www.w3.org/1999/xlink%27 viewBox=%270 0 330 330%27 xml:space=%27preserve%27%3E%3Cpath id=%27XMLID_222_%27 d=%27M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001 c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213 C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606 C255,161.018,253.42,157.202,250.606,154.389z%27/%3E%3C/svg%3E");
}

.table-list-packages .collapsed .row-text::before{
  transform: rotate(90deg);
}

.table-list-packages{
    margin-top: 20px !important;
}

.section-price-account .table-list-packages .row-text::after{
 left: -5px !important;
}

@media(min-width:508px){
    .table-list-packages{
        margin-top: 0px !important;
    }
    
}
