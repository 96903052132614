.section-price-account {
  border-color: #4f969c;
  background: #48898F4A;
  border-radius: 10px;
  padding: 30px 20px;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section-price-account ul{
  list-style: none !important;
  padding: none !important;
}

.section-price-account .title {
  width: 100%;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
}

.section-price-account .text {
  margin-bottom: 1rem;
  text-align: center;
  color: #ffffff;
  max-width: 750px;
  font-size: 16px;
}

.section-price-account .list-trade {
  display: flex;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  margin-bottom: 0.75rem;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  border-width: 1px;
  border-color: #b0ef96;
}

.section-price-account .list-trade {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.section-price-account .button-traders {
  display: flex;
  position: relative;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-width: 1px;
  outline-style: none;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
  color: #ffffff;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  transition-duration: 300ms;
  width: 80px;
  height: 80px;
  border-radius: 10px;
}

.section-price-account .trader-active {
  border-color: #4f969c !important;
}

.section-price-account .trader-active:hover {
  border-color: #b0ef96;
}

.section-price-account .trader-not-active {
  opacity: 0.7;
  border-color: #5e954730;
  background: #393939;
}

.section-price-account .selected-trader {
  border-color: #b0ef96;
  background: #b0ef96;
}

.section-price-account .logo-trader {
  object-fit: contain;
  width: 45px;
  height: 45px;
}

.section-price-account .name {
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.section-price-account .name-active {
  color: #0b352b;
}

.section-price-account .name-inactive {
  color: white;
}

.section-price-account .list-price {
  display: flex;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 0;
  border:1px solid #b0ef96;
}

.section-price-account .button-price {
  position: relative;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border:1px solid #b0ef96;
  outline-style: none;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
  color: #ffffff;
  background-color: transparent;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  height: 45px;
  width: 60px;
  border-radius: 10px;
}

.section-price-account .button-price:hover {
  border-color: #b0ef96;
}

.section-price-account .list-desktop .button-price-inactive {
  border-color: #4f969c;
}

.section-price-account .list-desktop .button-price-active {
  border-color: #b0ef96;
}


.section-price-account .list-price-mobile .button-price {
  border-color: #4f969c;
}

.section-price-account .badge {
  position: absolute;
  top: -10px;
  right: 0;
  left: 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 10px;
  width: max-content;
  color: 10px;
  background: #b0ef96;
  color: #0b352b;
  margin: auto;
}

.section-price-account .table-list-trader {
  display: flex;
  overflow: hidden;
  margin-top: 1.5rem;
  flex-direction: column;
  gap: 0px;
  justify-content: space-between;
  border-radius: 0.5rem;
  width: 100%;
  backdrop-blur: blur(8px);
  background: #48898f4a;
  border: 1px solid #4f969c;
  max-width: 580px;
}

.section-price-account .table-list-trader .rows-active {
  display: grid;
  padding-top: 12px;
  align-items: center;
  padding-bottom: 12px;
  padding-left: 1rem;
  padding-right: 1rem;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  background: #23565fc9;
}

.section-price-account .table-list-trader .rows-inactive {
  display: grid;
  padding: 12px 0;
  padding-left: 1rem;
  padding-right: 1rem;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.section-price-account .table-list-trader .rows-title {
  font-weight: 600;
  text-align: center;
  font-size: 14px !important;
  color: #ffffff;
}

.section-price-account .table-list-trader .row-text {
  font-weight: 600;
  font-size: 16px;
  color: #d1d5db;
}

.section-price-account .table-list-trader .content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-price-account .table-list-trader .title-content {
  text-align: center;
  font-size: 16px;
  width: 120px;
  height: 18px;
  color: #ffffff;
}

.section-price-account .table-list-trader .container-button {
  display: flex;
  padding: 0.5rem;
  flex-wrap: wrap;
  gap: 0.75rem;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.section-price-account .table-list-trader .container-button .text {
  font-weight: 600;
  color: #b0ef96;
  font-size: 36px;
  margin: 0;
  width: 114px;
  height: 54px;
}
.section-price-account .table-list-trader .button-send{
  margin-top: 0;
  width: max-content;
}
.section-price-account .title{
  text-align: center;
  font-size: 20px;
  color: white;
}
@media(min-width:992px){
  .section-price-account .title{
    text-align: left;
  }
}

/*
.table-list-trader .button-send {
  position: relative;
  margin: 0;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-width: 1px;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
  color: #ffffff;
  background: transparent;
  border-radius: 10px;
  text-transform: uppercase;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  width: 160px;
  outline: 0 !important;
  border: 1px solid #b0ef96;
}

.table-list-trader .button-send:hover {
  color: #000000;
  border-color: #b0ef96;
  background: #b0ef96;
}
*/
.section-price-account .table-list-trader .fade-content{
  position: absolute;
  left: 0;
  right: 0;
  transition: all 0.8s ease;
}

.section-price-account .table-list-trader .fade-inactive{
  transform: translateY(20px);
  opacity: 0;
}

.section-price-account .table-list-trader .fade-active{
  transform: translateY(0px);
  opacity: 1;
}


@media (min-width: 768px) {
  .section-price-account .list-price {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}

@media (min-width: 1024px) {
  .section-price-account .table-list-trader .button-send {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
  }

  .section-price-account .title{
    font-size: 20px;
  }
}

.section-price-account .slick-list{
  padding-top: 10px !important;
}
.section-price-account .list-price-mobile {
  display: block ;
  padding: 10px 0;
  padding-top: 0px;

}

.section-price-account .slick-center .button-price{
  border-color: #b0ef96;
}


.section-price-account .list-price button{
  outline: 0;
}

.section-price-account .list-price-mobile .button-price{
  width: 90%;
}

@media(max-width:478px){
  .section-price-account .list-price{
    display: flex;
    flex-flow: wrap;
    place-items: center;
    justify-content: center;
    margin-bottom: 0;
    align-items: center;
    max-width: 280px !important;
  }
  
}

@media(min-width:992px){
  .section-price-account{
    justify-content: center;
    align-items: center;
  }

  .section-price-account .list-trade{
    justify-content: center;
    margin-left: 0;
    padding-left: 0;
    padding-top: 0;
  }

  .section-price-account .list-price{
    margin: auto;
    max-width: max-content;
  }

  .section-price-account .table-list-trader{
    margin: auto;
    margin-top: 20px;
  }

  .section-price-account .title-account{
    font-size: 18px;
    margin-bottom: 15px;
  }

  .section-price-account .title-account{
    text-align: center;
  }
}

@media(max-width:992px){
  .section-price-account .list-price{
    max-width: max-content;
    margin: auto;
    margin-top: 20px;
  }

  .section-price-account .table-list-trader{
    margin: auto;
    margin-top: 20px;
  }
}



/*Page my accounts*/

.container-info-funded-accounts .section-price-account .button-traders{
  width: 70px;
  height: 70px;
}

.container-info-funded-accounts .section-price-account .logo-trader{
  width: 35px;
  height: 35px;
}

.container-info-funded-accounts .section-price-account .list-price{
  margin-top: 0;
  max-width: 230px;
}

.container-info-funded-accounts .section-price-account .table-list-trader .row-text{
  position: relative;
  text-align: center;
  padding-left: 26px;
  font-size: 14px;
  margin-bottom: 0px;
}

.container-info-funded-accounts .section-price-account .accordion-item-list-two{
  background: #275961;
}

.container-info-funded-accounts .section-price-account .accordion-item-list-one .accordion-item-list{
  background: transparent;
}

.section-price-account .table-list-trader .row-text::before{
  content: '';
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  margin: auto;
  margin-left: 0px;
  left: -5px;
  transform: rotate(270deg);
  filter: invert(1);
  width: 18px;
  height: 22px;
  position: absolute;
  transition: all 0.3s ease-in-out;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 10px;
  background-image: url("data:image/svg+xml,%3C%3Fxml version=%271.0%27 encoding=%27iso-8859-1%27%3F%3E%3C%21-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --%3E%3Csvg fill=%27%23000000%27 height=%27800px%27 width=%27800px%27 version=%271.1%27 id=%27Layer_1%27 xmlns=%27http://www.w3.org/2000/svg%27 xmlns:xlink=%27http://www.w3.org/1999/xlink%27 viewBox=%270 0 330 330%27 xml:space=%27preserve%27%3E%3Cpath id=%27XMLID_222_%27 d=%27M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001 c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213 C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606 C255,161.018,253.42,157.202,250.606,154.389z%27/%3E%3C/svg%3E");
}

.container-info-funded-accounts .section-price-account .table-list-trader .row-text::after{
  content: '';
  border-radius: 3px;
  border: 1px solid #a767b3;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  margin: auto;
  margin-left: 0px;
  left: -5px;
  transform: rotate(90deg);
  filter: invert(1);
  width: 18px;
  height: 22px;
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 10px;
}

.container-info-funded-accounts .accordion-item-list{
  font-size: 14px;
  text-align: left;
}

.container-info-funded-accounts .accordion-item-list div{
  padding: 10px 10px;
}

.container-info-funded-accounts .table-list-trader .title-content{
  margin-bottom: 0;
}

