
.section-users-admin .container-cards-users{
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
}

.section-users-admin .container-cards-users .user-card{
    border: 1px solid #b0ef96a8;
    background: #19513c33;
    border-radius: 10px;
    padding: 10px 15px;
    transition: all 0.3s ease;
    cursor: pointer;
    height: 100%;

}

.section-users-admin .input-email{
    width: 80%;
    min-width: 300px;
    height: 35px;
    padding: .5rem;
    margin-top: 5px;
    border-radius: 10px;
    outline: none;
    font-size: 16px;
    border: none;
    background-color: transparent;
    border: 1px solid #4f969c;
    color: rgba(255, 255, 255, 0.692);
    transition: all 0.3s ease;
}

.section-users-admin .input-email::placeholder{
    color: white;
}

.section-users-admin .container-cards-users .user-card:hover{
    background-color: #04372436;
}

.modal-admin .select-modal{
    display: block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 0.5rem;
    border-width: 1px;
    outline-style: none;
    margin-top: 5px;
    height: 38px;
    width: 100%;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #ffffff;
    background-color: transparent;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
    background: #285c64cc;
    color: white !important;
    border: 1px solid #4f969c;
}

.section-users-admin .label-buyer{
    background: #b0ef96;
    color: black;
    font-weight: 500;
    padding: 2px 6px;
    width: max-content;
    font-size: 14px;
}

.section-users-admin .container-cards-users .user-label{
    font-size: 18px;
    font-weight: 600;
    color: white;
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.section-users-admin .container-cards-users .user-title{
    border-bottom: 2px solid #b0ef9687;
    padding-bottom: 1px;
    margin-bottom: 7px;
    font-size: 16px;
    color: white;
}

.container-admin-user h3,
.container-admin-user .title-dashboard-user{
    font-size: 20px;
    font-weight: 600;
    color: white;
}

.container-admin-user .content-info-user{
    border-bottom: 2px solid #b0ef96;
    padding-bottom: 10px;
}

@media(min-width:1024px){
    .section-users-admin .container-cards-users{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }

    .section-users-admin .container-cards-users .user-label{
        font-size: 16px;
        font-weight: 600;
        color: white;
        padding-bottom: 0px;
        margin-bottom: 0px;
    }
}
