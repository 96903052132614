.advice-element{
    border-color: #4f969c;
    background: #48898f4a;
    padding: 10px 20px;
    border-radius: 10px;
    color: white;
    max-width: 591px;
    font-weight: 500;
    margin: auto;
}

.advice-element .container-message{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.advice-element img{
    width: 80px;
    /* filter: invert(1); */
    background: #b0ef96bd;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
}

.advice-element p{
    margin: 0;
}

.advice-element .button-send{
    text-decoration: none;

}

.advice-element .button-send svg{
    fill: black;
}

.advice-element .button-send div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}