.login-container{
    display: flex;
    justify-content: center;
}
.container-login-section form{
    background: #48898F4A;
    box-shadow: none;
    border:1px solid #B0EF96 !important;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 75vh;
    width: 100%;
    padding: 20px;
    max-width: 440px;
    gap: 20px;
}


.container-login-section .button-send {
    position: relative;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 1rem;
    border-width: 1px;
    outline-style: none;
    width: 100%;
    border-radius: 10px;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 600;
    color: #000000;
    border-color: #b0ef96;
    background: #b0ef96;
    text-transform: uppercase;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
    border: 1px solid #b0ef96;
    max-width: 350px;
}

.container-login-section .button-send:hover{
    background-color: #6aaf4e;
}

.container-login-section .input-login{
    width: 100%;
    padding:.5rem;
    border-radius: 10px;
    outline: none;
    border: none;
    background-color: transparent;
    border: 1px solid #4f969c;
    color:rgba(255, 255, 255, 0.692);

}

.container-login-section .field-input{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    max-width: 350px;
}

.container-login-section .field-input label{
    color:white;
    font-weight: 600;
    margin-bottom: 7px;
}

.container-login-section .input-login::placeholder{
    color:rgba(255, 255, 255, 0.692);
}

.container-login-section .login-text{
    color: #B0EF96;
    font-size: 30px;
}

.container-login-section .input-login{
    transition: all 0.3s ease;
}
.container-login-section .input-login:focus{
    border: 1px solid #B0EF96;
    outline: none;
}
.container-login-section .Login-btn{
    background-color: #B0EF96;
    border: none;
    padding: .4rem 3rem;
    border-radius: 10px;
    color: #0B352B;
    font-weight: bold;
    font-size: 20px;
}
.container-login-section .acc-text{
    color: white;
}
.container-login-section .register-text{
    color: #B0EF96;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
}

.container-login-section .register-text:hover{
    color:#90d773;
}

.container-login-section .eye-icon{
    position: absolute;
    right: 6%;
    cursor: pointer;
    top: 32%;
    color: #B0EF96;
}
@media screen and (max-width:768px){

    .container-login-section .Login-btn{
        padding: .4rem 2rem;
        font-size: 18px;
    }
    .container-login-section .register-text{
        font-size: 17px;
    }
}
@media screen and (max-width:376px){

    .container-login-section .Login-btn{
        padding: .3rem 1rem;
        font-size: 16px;
    }
    .container-login-section .register-text{
        font-size: 16px;
    }
}