.container-account-details .rows-information{
    display: grid;
    width: 100%;
    align-items: center;
    padding: 0px 0;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    font-size: 16px;
    color: white;
}

.container-account-details .row-text{
    position: relative;
    font-size: 14px;
    font-weight: 600;
}

.container-account-details .rows-information .title-content{
    text-align: left;
    font-weight: 600;
    font-size: 14px;
    padding-left: 25px;
}

.section-account-details, .advice-account-not-found{
    margin: auto;
    margin-top: 0px;
    display: flex;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 1.5rem;
    flex-direction: column;
    gap: 0.75rem;
    border-radius: 1.5rem;
    width: 100%;
    backdrop-blur: blur(8px);
    border-color: #4f969c;
    background: #48898f4a;
}

.section-account-details{
    margin-bottom: 5px;
}

.section-account-details .row-active{
    background-color: #23565fc9;
}

.section-account-details .rows-information{
    padding: 10px 15px;

}

.section-account-details .accordion-collapse div{
    padding: 0 15px;
    padding-bottom: 10px;
    font-size: 14px;
}

.section-account-details .rows-contents{
    border-top: 0px solid white;
    padding-top: 15px;

}

.section-account-details .row-container{
    border-top: 1px solid white;
    cursor: pointer;
}

.section-account-details .container-rules{
    padding: 0;
    margin-top: 0;
    border-radius: 0.5rem;
    border-width: 1px;
    width: 100%;
    backdrop-blur: blur(8px);
    border-color: #4f969c;
    background: transparent;
    border-color: #4f969c;
    background: transparent;
    border: 1px solid #4f969c;
}

.section-account-details .content .icon-check,
.section-account-details .content .icon-check img{
    width: 25px;
    height: 25px;
}

.section-account-details .content .icon-check img{
    position: relative;
    z-index: 1;
}


.section-account-details .icon-check .bg-aux{
    position: absolute;
    top: 10px;
    bottom: 10px;
    width: 13px;
    height: 13px;
    background: white;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 0;
}

.section-account-details .rows-information .fade-content{
    gap: 7px;
}


.section-account-details .accordion-item-list{
    color:white;
}


.section-account-details .rows-contents  .row-text::before {
    content: '';
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    bottom: 0;
    margin: auto;
    margin-left: 0px;
    left: -5px;
    transform: rotate(270deg);
    filter: invert(1);
    width: 18px;
    height: 22px;
    position: absolute;
    transition: all 0.3s ease-in-out;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 10px;
    background-image: url("data:image/svg+xml,%3C%3Fxml version=%271.0%27 encoding=%27iso-8859-1%27%3F%3E%3C%21-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --%3E%3Csvg fill=%27%23000000%27 height=%27800px%27 width=%27800px%27 version=%271.1%27 id=%27Layer_1%27 xmlns=%27http://www.w3.org/2000/svg%27 xmlns:xlink=%27http://www.w3.org/1999/xlink%27 viewBox=%270 0 330 330%27 xml:space=%27preserve%27%3E%3Cpath id=%27XMLID_222_%27 d=%27M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001 c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213 C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606 C255,161.018,253.42,157.202,250.606,154.389z%27/%3E%3C/svg%3E");
}

.section-account-details .rows-contents  .row-text::after{
    content: '';
    border-radius: 3px;
    border: 1px solid #a767b3;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    bottom: 0;
    margin: auto;
    margin-left: 0px;
    left: -5px;
    transform: rotate(270deg);
    filter: invert(1);
    width: 18px;
    height: 22px;
    position: absolute;
}

.section-account-details .row-container .row-text{
    padding-left: 25px;
}

.section-account-details .collapsed .row-text::before{
    transform: rotate(90deg) !important;
}

.container-account-details .btn-credencials{
    position: relative;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-size: 14px;
    height: 28px;
    padding: 0 10px;
    border-radius: 3px;
    background-color: #21614fe5;
    color: white;
    font-weight: 600;
    border-radius: 5px;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
    outline: 0;
    border: 1px solid #b0ef96;
}

.container-account-details .btn-credencials:hover{
    background-color: #0635287a;
}

.container-account-details .status{
    position: relative;
    display: flex;
    align-items: center;
    height: 28px;
    color: white;
    font-weight: 600;
    font-size: 14px;
}

.container-account-details .status::after{
    content:'';
    position: absolute;
    width: 10px;
    height: 10px;
    left: -15px;
    border-radius: 20px;
}

.container-account-details .account-active::after{
    background-color: #3bd13b;
}

.container-account-details .account-process::after{
    background-color: rgb(255, 123, 0) !important;
}

.container-account-details .account-suspense::after{
    background-color: rgb(217 51 51);
}

.container-account-details .icon-btn{
    width: 15px;
    height: 16px;
    margin-right: 6px;
    filter: invert(1);
    object-fit: contain;
    margin-top: -2px;
}

.container-account-details .lds-dual-ring:after{
    border-color:white transparent white transparent
}

.container-account-details .container-chart{
    border-color: #4f969c;
    background: #48898f4a;
}

.container-account-details .container-account{
    padding: 10px;
    border-radius: 10px;
    border-color: #4f969c;
    background: #48898f4a;
}

.container-account-details .container-account .content-row{
    height: 40px;
    font-size: 14px;
    justify-content: center;
    border-top: 1px solid white;
}

.container-account-details .container-account .content-row:first-of-type{
    border-top: 0px;
}

.container-account-details .title{
    margin-top: 20px;
    font-size: 25px;
}

.container-account-details .container-orders{
    border-color: #4f969c;
    background: #48898f4a;
    padding: 10px;
    border-radius: 10px;
}

.container-account-details .container-orders  p{
    margin: 0;
    padding: 7px 5px;
    border: 1px solid #589a9f;
}
.container-account-details .container-orders .orders-label{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    color: white;
    align-items: center;
    justify-content: center;
    text-align: left;
    font-weight: 600;
}

.container-account-details .container-orders .orders-label p{
    background: #186969;

}

.container-account-details .container-orders .orders-data .orders-row{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    color: white;
    font-weight: 600;
    font-size: 14px;
}

.container-account-details .container-orders .label-quotex,
.container-account-details .container-orders .orders-data .order-row-quotex{
    grid-template-columns: repeat(6, 1fr);
}

.container-account-details .container-orders .date-field{
    font-size: 12px;
}

.container-account-details .container-orders  .loose{
    position: relative;
    color:#ff5555;
    
}

.container-account-details .container-orders  .win{
    position: relative;
    color:#5fe75f;
}
.container-account-details .container-orders .win span,
.container-account-details .container-orders .loose span{
    position: relative;
}

.container-account-details .container-orders .win .ind:after,
.container-account-details .container-orders .loose .ind:after{
    content:'';
    position: absolute;
    top: 0;
    bottom: 0;
    right: -30px;
    margin: auto;
    background-size: 28px;
    width: 28px;
    height: 28px;
   /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='40px' viewBox='0 -960 960 960' width='40px' fill='%23e8eaed'%3E%3Cpath d='M480-360 280-559.33h400L480-360Z'/%3E%3C/svg%3E");*/
}

.container-account-details .container-orders .win .ind:after{
    transform:rotate(180deg);
 /*   background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='40px' viewBox='0 -960 960 960' width='40px' fill='%2375ed7e'%3E%3Cpath d='M480-360 280-559.33h400L480-360Z'/%3E%3C/svg%3E");
*/}

.container-account-details .container-orders .loose .ind:after{
    transform:rotate(0deg);
  /*  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='40px' viewBox='0 -960 960 960' width='40px' fill='%23f77070'%3E%3Cpath d='M480-360 280-559.33h400L480-360Z'/%3E%3C/svg%3E");
*/}

.container-account-details .container-orders .label-title{
    display: none;
}

.container-account-details .container-orders .ind {
    max-width: max-content;
    display: flex;
}

.container-account-details .container-orders .ind img{
    width: 30px;
    height: 30px;
    z-index: 2;
}

.container-account-details .container-orders .ind span{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background: white;
    display: flex;
    width: 17px;
    height: 17px;
    z-index: -1;
}

.container-account-details .data-win .label-title{
    color: white;
}

.container-account-details .container-orders .label-quotex,
.container-account-details .container-orders .order-row-quotex{
    grid-template-columns: repeat(6, 1fr);
    align-items: stretch;
}

.container-account-details .container-orders .label-quotex button,
.container-account-details .container-orders .order-row-quotex button{
    border: none;
    background-color: transparent;
    filter: invert(1);
    padding: 0;
    padding-left: 4px;
    outline: 0;
}

.container-account-details .container-orders .orders-data .orders-row button,
.container-account-details .container-orders .orders-label button{
    border: none;
    background-color: transparent;
    color: white;
    outline: none;
}

.container-account-details .container-orders .orders-label button svg{
    filter: invert(0);
}

.container-account-details .container-orders label{
    color: white;
}

.container-account-details .container-orders .arrow-down,
.container-account-details .container-orders .arrow-up{
    position: relative;
    display: flex;
    transform:rotate(0deg) scale(1.7);
    background-size: 30px;
    width: 30px;
    height: 30px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='40px' viewBox='0 -960 960 960' width='40px' fill='%23f77070'%3E%3Cpath d='M480-360 280-559.33h400L480-360Z'/%3E%3C/svg%3E");
}

.container-account-details .container-orders .arrow-up{
    transform:rotate(180deg) scale(1.7);
    
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='40px' viewBox='0 -960 960 960' width='40px' fill='%2375ed7e'%3E%3Cpath d='M480-360 280-559.33h400L480-360Z'/%3E%3C/svg%3E");
}

.container-account-details .container-orders .content-check-desktop label{
    display: none;
}

@media(max-width:785px){
    .container-account-details .orders-data{
        gap: 10px;
        display: flex;
        flex-direction: column;
    }

    .container-account-details .container-orders .content-check-desktop label{
        display: flex ;
    }

    .container-account-details .container-orders .label-title{
        display: flex;
    }
    .container-account-details .container-orders  .orders-label{
        display: none;
    }

    .container-account-details .container-orders p{
        border: 1px solid #5887959e;
    }

 

    .container-account-details .container-orders .orders-data .orders-row{
        display: grid; 
        grid-template-columns: 1fr 1fr; 
        grid-template-rows: 1fr 1fr 1fr; 
        gap: 0px 0px;
        border-top: 1px solid white; 
        grid-template-areas: 
          "data-inital data-active"
          "data-amount data-win"
          "data-end data-end"; 
      }
      .container-account-details .container-orders .orders-data  .data-inital { grid-area: data-inital; }
      .container-account-details .container-orders .orders-data  .data-active { grid-area: data-active; }
      .container-account-details .container-orders .orders-data .data-amount { grid-area: data-amount; }
      .container-account-details .container-orders .orders-data  .data-win { grid-area: data-win; }
      .container-account-details .container-orders .orders-data  .data-end { grid-area: data-end; }
      .container-account-details .container-orders .orders-data  .data-direction { grid-area: data-direction; }


     .container-account-details .container-orders .orders-data .order-row-quotex{
        grid-template-areas: 
          "data-inital data-active"
          "data-amount data-win"
          "data-direction data-end"; 
      }

      .container-account-details .container-orders .orders-data .orders-row{
        display: grid;
        flex-direction: column;
      }

      .container-account-details .container-orders .orders-data .orders-row .data-direction span,
      .container-account-details .container-orders .orders-data .orders-row .data-win span{
        display: flex;
        flex-direction: column;
        gap: 3px;
      }

      .container-account-details .container-orders .orders-data .orders-row .data-direction .label-title,
      .container-account-details .container-orders .orders-data .orders-row .data-win .label-title{
        flex-direction: row !important;
      }

     

      .container-account-details .container-orders .label-quotex,
.container-account-details .container-orders .order-row-quotex{
    grid-template-columns: repeat(2, 1fr) !important;
}
}