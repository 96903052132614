.container-admin-support-messages .section-support-admin{
    margin: auto;
    margin-top: 0px;
    display: flex;
    padding-top: 0.5rem;
    padding-bottom: 20px;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 1.5rem;
    flex-direction: column;
    gap: 0.75rem;
    border-radius: 1.5rem;
    width: 100%;
    backdrop-blur: blur(8px);
    padding: 15px 20px;
    border-color: #4f969c;
    background: #48898F4A;
    border-color: 1px;
}

.container-admin-support-messages .section-support-admin .title{
    font-size:20px
}

.container-admin-support-messages .message{
    border: 1px solid #b0ef96a8;
    background: #19513c33;
    border-radius: 10px;
    padding: 10px 15px;
    transition: all 0.3s ease;
    cursor: pointer;
    color: white;
}

.container-admin-support-messages .message:hover{
    background-color: #04372436;
}

.container-admin-support-messages .message span{
    font-weight: 700;
}

.container-admin-support-messages .message p{
    margin: 0;
    font-size: 16px;
}

.container-admin-support-messages .message .content-message{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis ;
    max-width: 200px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

@media(min-width:1024px){
    .container-admin-support-messages .container-messages{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }
}