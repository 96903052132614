.container-platforms{
    border-color: #4f969c;
    background: #48898f4a;
    padding: 20px;
    border-radius: 10px;
}

.container-platforms h3{
    font-size: 20px;
    color: white;
    font-weight: 600;
    margin-bottom: 20px;
}



.container-platforms .platform img{
    width: 100%;
    height: 100px;
    object-fit: contain;
    max-width: 200px;
}

.container-platforms .platform{
    background: #a1d78bf2;
    border-radius: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 250px;
    padding: 8px 10px;
    transition: all 0.3s ease;
}

.container-platforms .active:hover{
    background-color: #88c36ff2;
}

.container-platforms .inactive{
    filter: grayscale(0.41);
    opacity: 0.3;
}
