.container-admin-tickets .title-section{
    font-size: 26px;
}

.container-admin-tickets .section-tickets-admin{
    border-color: #4f969c;
    background: #48898F4A;
    border-radius: 10px;
    padding: 15px 10px ;
    padding-bottom: 20px;
}

.container-admin-tickets .title-tickets{
    font-size: 24px;
    color: white;
}

.container-admin-tickets  .section-tickets{
    padding: 0 !important;
}