.section-accounts{
    margin: auto;
    margin-top: 0px;
    display: flex;
    padding-top: 0.5rem;
    padding-bottom: 20px;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 1.5rem;
    flex-direction: column;
    gap: 0.75rem;
    border-radius: 1.5rem;
    width: 100%;
    backdrop-blur: blur(8px);
    border-color: #4f969c;
    background: #48898F4A;
    border-color: 1px;
}

.section-accounts .title-account{
    color: white;
    margin-top: 10px;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 20px;
}

.section-accounts .account{
    color: white;
    background: #3b6e72;
    border: 2px solid #699d7b;
    border-radius: 10px;
    padding: 20px;
}

.section-accounts .indicator-server{
    font-size: 16px;
    background: #b0ef96;
    border-radius: 2px;
    color: black !important;
    font-weight: 500;
    padding: 2px 13px;
}

.section-accounts .btn-data span{
    color: white !important;
}

.section-accounts .account h3{
    display: flex;
    align-items: center;
    gap: 14px;
    text-transform: capitalize;
    font-size: 20px;
}

.section-accounts .account p{
    color: #e3e3e3;
    font-size: 16px;
    font-weight: 700;
}

.section-accounts .account span{
    font-weight: 600;
    color:#aced91;
}

.section-accounts .btn-data{
    background: transparent;
    width: max-content;
    border: none;
    padding: 6px 10px;
    padding-left: 0;
    border-radius: 10px;
    color: white;
    outline: 0;
    font-size: 18px;
    transition: all 0.3s ease;
}
/*
.section-accounts .btn-data:hover{
    background:#13373bc4;
}
*/

.section-accounts .content-icon img{
    width: 20px;
    height: 20px;
    object-fit: contain;
}

.section-accounts .content-icon{
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0f5b4952;
    border: 1px solid #b0ef96;
    /* border: 2px solid #699d7b; */
    border-radius: 7px;
}

.section-accounts .list-accounts{
    max-height: 0px;
    overflow: hidden;
    transition: all 0.3s ease;
}

.section-accounts .list-accounts-active{
    /*max-height: max-content;*/
}

.section-accounts .form-check-input{
    --bs-form-switch-bg:url(data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27><circle r=%273%27 fill="white"/></svg>);
    --bs-form-check-bg: #fff0;
    border:1px solid white;
    height: 26px;
    width: 59px;
}

.section-accounts .form-switch .form-check-input:focus{
    box-shadow: none;
}

.section-accounts .form-check-input:checked{
    --bs-form-switch-bg:url(data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='rgba%280, 0, 0, 0.8%29'/></svg>);
    background-color: #b0ef96;
    border-color: #b0ef96;
}
/*modal-custom-accounts*/

.modal-custom-accounts{
    display: flex !important;
}

.modal-custom-accounts .link-modal{
    color: #b0ef96;
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
    transition: all 0.3s ease;

}

.modal-custom-accounts .link-modal:hover{
    color:#8bcb71;
}

.modal-custom-accounts .modal-dialog{
    margin: auto;
    width: 100%;
    padding: 20px 10px;
}

.modal-custom-accounts .modal-header{
    background: #206369de;
}

.modal-custom-accounts .modal-title{
    font-size: 19px;
}

.modal-custom-accounts .modal-footer{
    background: #206369de;
}

.modal-custom-accounts .modal-content{
    background: #206369de;
    color: white;
}

.modal-custom-accounts .btn-close{
    --bs-btn-close-color:white;
    filter: invert(1);
    outline: 0 !important;
    border: none;
    opacity: 1;
}

.modal-custom-accounts .modal-body h3{
    margin: 0;
    font-size: 20px;
    font-weight: 600;
}


.modal-custom-accounts .hide-message{
    display: none;
}

.modal-custom-accounts .modal-body .video-title{
    font-size: 18px;
    text-align: left;
    margin-bottom: 15px;
}

.section-accounts .button-send{
   text-transform: capitalize;
}

.section-accounts  .description-title {
    font-size: 17px;
}

.modal-custom-accounts .modal-content .url-server{
    text-decoration: none;
    color: white;
    text-transform: capitalize;
    color: #6ed96e;
    font-weight: 600;
    transition: all 0.3s ease;
}

.modal-custom-accounts .modal-content .url-server:hover{
    color:#33c133;
}

.section-accounts .button-accounts{
    color: white;
    background-color: #21614f7d;
    font-size: 13px;
    padding: 10px 10px;
}

.section-accounts .button-accounts:hover{
    background-color: #0635287d;
}

.modal-create-account input{
    width: 100%;
    padding: .5rem;
    margin-top: 5px;
    border-radius: 10px;
    outline: none;
    font-size: 16px;
    border: none;
    background-color: transparent;
    border: 1px solid #4f969c;
    color: rgba(255, 255, 255, 0.692);
    transition: all 0.3s ease;
}

.modal-create-account input::placeholder{
    color: rgba(255, 255, 255, 0.692);
}

.modal-create-account input:focus{
    border: 1px solid #6ed96e;
}

.modal-create-account  .modal-body h3 {
 font-size: 16px;
}

.modal-create-account .eye-icon{
    position: absolute;
    right: 10px;
    top:15px;
    width:22px;
    height: 22px;
}

@media(max-width:992px){
    .section-accounts .account h3 {
        font-size: 19px;
        font-weight: 600;
        margin-top: 10px;
    }

    .section-accounts .title-account{
        text-align: center;
    }
}

@media(max-width:507px){
    .section-price .list-price{
        display: flex;
        flex-flow: wrap;
        place-items: center;
        justify-content: center;
        margin-bottom: 0;
        align-items: center;
        max-width: 230px;
      }
}