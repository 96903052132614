.footer {
  margin-top: 1.5rem;
  border-radius: 1.5rem;
  border-width: 1px;
  width: 100%;
  backdrop-blur: blur(8px);
  border-color: #4f969c;
  background: #48898f4a;
  border-color: 1px;
  margin: auto;
}

.footer p:hover{
    color:white !important;
}

.footer .copyright{
  text-align: center;
}
.footer .text-copyright{
  text-align: center;
}
.footer .input-email {
  display: flex;
  gap: 3px;
  padding: 1rem;
  border-radius: 0.5rem;
  border-width: 1px;
  outline-style: none;
  width: 100%;
  font-size: 0.875rem;
  justify-content: space-between;
  line-height: 1.25rem;
  color: #ffffff;
  background-color: transparent;
  border-color: #4f969c;
  border:1px solid #4f969c;
}

.footer .input-email input {
  outline: 0;
  color:white;
  border:none;
  background-color: transparent;
}

.footer .input-email input::placeholder{
    color:white;
}

.footer .container-grid {
  display: grid;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1rem;
}

.footer .text-disclaimer {
  padding-top: 1.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #f3f4f6;
}

.footer .text-disclaimer-2 {
  padding-top: 1.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
  color: #f3f4f6;
}

.footer .text-disclaimer-3 {
  padding-top: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
  color: #f3f4f6;
}

.footer .url-link {
  color: #ffffff;
}

.footer .text-copyright {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #f3f4f6;
}

@media (min-width: 640px) {
  .footer .text-copyright {
    text-align: center;
  }
}

.footer .url-link:hover {
  text-decoration: underline;
}

.footer .copyright-container {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.footer .link {
  display: flex;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  align-items: center;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 700;
  color: #111827;
}

.footer .container-social {
  display: flex;
  margin-top: 1.25rem;
  gap: 0.75rem;
  justify-content: flex-start;
  align-items: center;
}

.footer .container-newsletter {
  display: flex;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  border-radius: 1.5rem;
}

.footer .container-div {
  width: 100%;
}

.footer .container-div label {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 700;
  color: #ffffff;
}

.footer .legal-content {
  display: grid;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-top: 1rem;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
  align-items: flex-start;
}

.footer .legal-content .container-legal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer .title-legal {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
}

.footer .list-link {
    list-style: none !important;
    padding: 0;
  text-align: left;
  color: #6b7280;
}

.footer .list-link a{
    text-decoration:none !important;
}

.footer .button-send {
  background-color: #3e858b;
  padding: 10px;
  border-radius: 10px;
  max-width: 60px;
  margin-top: 0;
  width: 100%;
  justify-content: center;
  display: flex;
  color: white;
  border:none;
  height: 40px;
  transition: all 0.3s ease;
}

.footer .button-send svg{
    width: 20px;
}

.footer .button-send:hover {
  background-color: #b0ef96;
  color: black;
}

@media (min-width: 768px) {
  .footer .container-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .footer .copyright {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footer .container-div {
    width: 100%;
  }

  .footer .container-newsletter {
    flex-direction: column;
  }
}

.footer .social-icons {
  display: flex;
  gap: 30px;
}
.footer .icon:hover {
  background-color: initial;
  border: 1px solid #48898f;
  box-shadow: 0 0 1rem 0 #b0ef96;
  color: #b0ef96;
}
.footer .icon {
  border: 1px solid #48898f;
  border-radius: 30px;
  color: #b0ef96;
  font-size: 50px;
  padding: 10px;
  transition: all 0.3s ease;
}

@media (min-width:1024px) {
    .footer .container-newsletter {
        flex-direction: row;
      }
}

@media(max-width:768px){
  .footer .list-link li{
    text-align: left !important;
  }
}

@media(max-width:380px){
  .footer .button-send{
    margin-left: -10px;
  }
}