.modal-card-checkout .container-input-field input,
.modal-card-checkout .container-input-field select{
    display: block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 0.5rem;
    border-width: 1px;
    outline-style: none;
    width: 100%;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #ffffff;
    background-color: transparent;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
    background: #285c64cc;
    color: white !important;
    border: 1px solid #4f969c;
}

.modal-card-checkout input::-webkit-outer-spin-button,
.modal-card-checkout input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.modal-card-checkout input[type=number] {
  -moz-appearance: textfield;
}

.modal-card-checkout {
    padding: 0 !important;
}
.modal-card-checkout .modal-dialog{
    width: 100%;
    max-width: 1000px;
}
.modal-card-checkout .container-input-field input::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

.modal-card-checkout .container-input-field input::placeholder{
    color: #ffffff;
}

.modal-card-checkout .modal-content{
    border: 2px solid #b1e79b;
}

.modal-card-checkout .container-input-field{
    margin-top: 5px;
}

.modal-card-checkout .modal-title{
    font-weight: 600;
} 

.modal-card-checkout .error-message{
    color: #fba39a;
    font-weight: 500;
}

.modal-card-checkout .hide-message{
    display: none;
}

.container-page-check-pay .content-check{
    margin: auto;
    margin-top: 0px;
    display: flex;
    padding:20px 10px;
    padding-bottom: 30px;
    margin-bottom: 1.5rem;
    flex-direction: column;
    gap: 0.75rem;
    color: white;
    border-radius: 1.5rem;
    width: 100%;
    backdrop-blur: blur(8px);
    border-color: #4f969c;
    text-align: center;
    background: #48898F4A;
    max-width: 600px;
}

.container-page-check-pay .title-color{
    color: #93e37d;
    font-size: 30px;
    text-align: center;
}

.container-page-check-pay .title-text{
    font-size: 20px;
    text-align: center;
} 