.checkout-popup{
    position: fixed !important;
    left: 0;
    background-color: #00000091;
    transition: all 0.3s ease;
    opacity: 0;
    overflow: auto;
    padding: 10px;
    z-index: -1 !important;
}
button{
    cursor: pointer;
}
.checkout-success{
    position: absolute !important;
    min-height: 300px;
    height: max-content;
    max-height: 560px;
    overflow: auto;
    justify-content: center;
    align-items: flex-start;
    display: flex;
    text-align: center;
    margin: auto;
    padding: 0px !important;
    opacity: 0;
    transition: all 0.3s ease;
    z-index: -1;
}

.checkout-success .accordion-body,
.checkout-success .accordion-body h4,
.checkout-success .accordion-body p{
    margin:10px 0 !important;
    text-align: left !important;
}

.checkout-success .accordion-body .url-server{
    text-decoration: none;
    color: white;
    text-transform: capitalize;
    color: #6ed96e;
    font-weight: 600;
    transition: all 0.3s ease;
}

.checkout-success .accordion-body .url-server:hover{
    color:#33c133;
}

.checkout-success .information{
    width: 95%;
}

.checkout-success-activate{
    opacity: 1;
    max-width: 530px !important;
    z-index: 3 !important;
}


.checkout-popup .checkout-close{
    position: absolute;
    background: transparent;
    border: 1px solid #b0ef96;
    color: white;
    font-weight: 600;
    border-radius: 10px;
    width: 40px;
    height: 35px;
    right: 35px;
    transition: all 0.3s ease;
}
.checkout-popup .checkout-close:hover{
    color: #000000;
    border-color: #b0ef96;
    background: #b0ef96;
}
.checkout-popup-active{
    opacity: 1 !important;
    z-index: 1000 !important;
}

.checkout-popup .checkout-information{
    height: 100vh;
    overflow: auto;
    background-color: #0808086e;
}

.checkout-popup .checkout-infomation{
    padding: 20px;
    max-width: 900px;
    width: 95%;
    margin: auto;
    background: #2d5357;
    border: 1px solid #b0ef96;
    border-radius: 10px;
    color: white;
}

.checkout-popup h3{
    font-weight: 600;
}

.checkout-popup h3, .information p{
    text-align: center;
    margin: 9px 0;

}

.checkout-popup .information{
    margin-top: 40px;
    margin-bottom: 20px;
}

.checkout-popup .label{
    color: #b0ef96;
    font-weight: 600;
    font-size: 18px;
}

.checkout-popup .button-send{
    position: relative;
    padding-top: 0.75rem;
    margin-top: 0px;
    padding-bottom: 0.75rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-width: 1px;
    outline-style: none;
    width: 100%;
    border-radius: 10px;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 600;
    color: #ffffff;
    background-color: transparent;
    text-transform: uppercase;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
    max-width: unset;
    border: 1px solid #b0ef96;
}

.checkout-popup-process .button-send,
.checkout-success .button-send{
    color: #000000;
    border-color: #b0ef96;
    background: #b0ef96;
    max-width: unset;
}

.checkout-popup-process .button-send:hover,
.checkout-success .button-send:hover{
    background-color: #6aaf4e;
}

.checkout-success-activate{
    max-width:530px;
}
.checkout-success-activate h3{
    font-size: 23px;
}

.checkout-success-activate h3, .checkout-success-activate .information p{
    margin: 15px auto;
} 

@media(min-width:992px){
    .divider-pay{
        padding: 0;
        justify-content: flex-end;
    }
}

.checkout-popup-finished{
    display: none;
    opacity: 0 !important;
    z-index: -1 !important;
}
